import noimage from 'assets/images/noimage.png';
import noimageTransparent from 'assets/images/noimage_transparent.png';

export const getImageMenuCategory = (url) => {
  if (url) {
    // let splitUrl = url.split('upload/');
    // let newUrl =
    //   splitUrl[0] +
    //   'upload/ar_1:1,c_thumb,g_center,q_30,w_40,c_fill/' +
    //   splitUrl[1];
    // return newUrl;
    return url + '?quality=50';
  } else {
    return noimage;
  }
};

export const getImageThumnailList = (url) => {
  if (url) {
    // let splitUrl = url.split('upload/');
    // let newUrl =
    //   splitUrl[0] +
    //   'upload/ar_1:1,c_thumb,g_center,q_50,w_120,c_fill/' +
    //   splitUrl[1];
    // return newUrl;
    return url + '?quality=50&width=120&height=120';
  } else {
    return noimage;
  }
};

export const getImageThumnailGrid = (url, width, height) => {
  if (url) {
    // let splitUrl = url.split('upload/');
    // let newUrl =
    //   splitUrl[0] +
    //   'upload/ar_1:1,c_thumb,g_center,q_50,w_300,c_fill/' +
    //   splitUrl[1];
    // return newUrl;
    return url + '?quality=50&width=' + width + '&height=' + height;
  } else {
    return noimage;
  }
};

export const getImageFullPage = (url) => {
  if (url) {
    // let splitUrl = url.split('upload/');
    // let newUrl = splitUrl[0] + 'upload/ar_1:1,c_thumb,g_center,q_50,w_300,c_fill/' + splitUrl[1];
    // return newUrl;
    return url + '?quality=90&width=213';
  } else {
    return noimage;
  }
};

export const getImageTenant = (url) => {
  if (url) {
    return url + '?quality=50&width=213&height=213';
  } else {
    return noimageTransparent;
  }
};
