import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Typography, Grid, Button, Box, Input } from '@material-ui/core';
import ReDialog from 'components/ReDialog';
import RePrepItemAccordion from 'components/RePrepItemAccordion';
import RePackageItem from 'components/RePackageItem';
import ReButtonQuantity from 'components/ReButtonQuantity';
import { makeStyles } from '@material-ui/core/styles';
import { AddShoppingCart } from '@material-ui/icons';
import { convertCurrency } from 'functions/convertNumber';
import { sumTotalPriceWithPrepItem } from 'functions/sumTotalPriceWithPrepItem';
import { useGeneralContext } from 'context/GeneralContext';
import { useCartContext } from 'context/CartContext';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import { useAuthContext } from 'context/AuthContext';
import ReLazyImage from './ReLazyImage';
import ReVariantAccordion from './ReVariantAccordion';
import { getImageFullPage } from 'functions/imageOptimization';
const useStyles = makeStyles({
  textField: {
    backgroundColor: '#FFFFFF',
    display: 'block',
    width: '100%',
    border: 0,
    height: 40,
    paddingLeft: theme.spacing(2),
    borderRadius: 4,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    boxSizing: 'border-box',
  },
  input: {
    height: 40,
    padding: 0,
    margin: 0,
  },
  priceAndQtyContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonQty: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttonAddToCart: {
    marginTop: 20,
    width: '80%',
    left: `calc(50% - 80%/2)`,
  },
  buttonImage: {
    backgroundColor: 'transparent',
    borderRadius: theme.spacing(1),
    borderWidth: 'thin',
    borderStyle: 'solid',
    marginBottom: theme.spacing(1),
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  marginLeftRight: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const ReAddToCartModal = ({
  isSuggestion,
  item,
  closeModal,
  dataSuggestion,
}) => {
  const classes = useStyles();
  const { toastSuccess, getSellBandId } = useGeneralContext();
  const { addToCart } = useCartContext();
  const { getSetting, getColorApp } = useAuthContext();
  const { t } = useTranslation();
  const noderef = useRef();
  const [localState, setLocalState] = useState({
    qty: 1,
    additional_request: '',
    selectedItem: {},
  });
  const [isShowImage, setShowImage] = useState(false);
  const [indexVariantSelected, setIndexVariantSelected] = useState(-1);

  useEffect(() => {
    // STRINGIFY THEN PARSE, BECAUSE DEEP NESTED OBJECT CANT BE "SPREAD" AS USUAL,
    // SPREAD ONLY WORK ONLY FOR 1st LEVEL OF OBJECT (NOT NESTED OBJECT)
    let reConstructItem = JSON.stringify({ ...item });
    reConstructItem = JSON.parse(reConstructItem);
    updateLocalState({ selectedItem: { ...reConstructItem } });
    // eslint-disable-next-line
  }, []);

  const updateLocalState = useCallback((newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const handleChangeQuantity = (value) => {
    updateLocalState({
      qty: value,
    });
  };

  const mergeModifierWithAdditionalRequest = (
    additionalRequest,
    modifierGroup = [],
  ) => {
    let newAdditionalRequest = [];
    newAdditionalRequest = modifierGroup.reduce((acc, value) => {
      const modifiers = value.modifiers.filter((v) => v.checked);
      return [...acc, ...modifiers.map((v) => v.message)];
    }, []);
    if (additionalRequest) {
      newAdditionalRequest.push(additionalRequest);
    }
    return newAdditionalRequest;
  };

  const handleAddToCart = () => {
    getLocalState('selectedItem').quantity = getLocalState('qty');

    let modifierGroup = [];
    if (getLocalState('selectedItem').is_variant) {
      var variantSelected = getLocalState('selectedItem').variant_item.filter(
        (item) => item.checked,
      );

      modifierGroup = variantSelected[0].modifier_group;
    } else {
      modifierGroup = getLocalState('selectedItem').modifier_group;
    }

    getLocalState('selectedItem').additional_request =
      mergeModifierWithAdditionalRequest(
        getLocalState('additional_request'),
        modifierGroup,
      );
    delete getLocalState('selectedItem').modifier_group;
    getLocalState('selectedItem').timestamp = new Date().getTime();
    addToCart(getLocalState('selectedItem'));

    closeModal();
    toastSuccess(
      `${getLocalState('qty')} x "${
        getLocalState('selectedItem').plu_name
      }" ${t('hasBeenAddedToCart')}`,
    );
  };

  const handleCheckVariant = (groupIndex, itemIndex) => {
    let localItem = { ...getLocalState('selectedItem') };
    setIndexVariantSelected(itemIndex);
    localItem.variant_item.map((item, index) => {
      return parseInt(itemIndex) === index
        ? (item.checked = true)
        : (item.checked = false);
    });
    updateLocalState({ selectedItem: localItem });
  };

  const handleCheckModifier = (groupIndex, itemIndex) => {
    let localItem = { ...getLocalState('selectedItem') };

    if (localItem.is_variant) {
      var variantSelected = localItem.variant_item.filter(
        (item) => item.checked,
      );

      let currentCheckValue =
        variantSelected[0].modifier_group[groupIndex].modifiers[itemIndex]
          .checked;

      if (!currentCheckValue) {
        variantSelected[0].modifier_group[groupIndex].modifiers[
          itemIndex
        ].checked = true;
      } else {
        variantSelected[0].modifier_group[groupIndex].modifiers[
          itemIndex
        ].checked = !currentCheckValue;
      }
    } else {
      let currentCheckValue =
        localItem.modifier_group[groupIndex].modifiers[itemIndex].checked;

      if (!currentCheckValue) {
        // IF THERE IS NO checked KEY THERE
        localItem.modifier_group[groupIndex].modifiers[
          itemIndex
        ].checked = true;
      } else {
        // ELSE, INVERT THE VALUE OF checked KEY
        localItem.modifier_group[groupIndex].modifiers[itemIndex].checked =
          !currentCheckValue;
      }
    }
    updateLocalState({ selectedItem: localItem });
  };

  const handleCheckPrepItem = (groupIndex, itemIndex) => {
    let localItem = { ...getLocalState('selectedItem') };

    if (localItem.is_variant) {
      var variantSelected = localItem.variant_item.filter(
        (item) => item.checked,
      );

      let currentCheckValue =
        variantSelected[0].prep_group[groupIndex].prep_item[itemIndex].checked;

      if (!currentCheckValue) {
        variantSelected[0].prep_group[groupIndex].prep_item[
          itemIndex
        ].checked = true;
      } else {
        variantSelected[0].prep_group[groupIndex].prep_item[itemIndex].checked =
          !currentCheckValue;
      }
    } else {
      let currentCheckValue =
        localItem.prep_group[groupIndex].prep_item[itemIndex].checked;

      if (!currentCheckValue) {
        // IF THERE IS NO checked KEY THERE
        localItem.prep_group[groupIndex].prep_item[itemIndex].checked = true;
      } else {
        // ELSE, INVERT THE VALUE OF checked KEY
        localItem.prep_group[groupIndex].prep_item[itemIndex].checked =
          !currentCheckValue;
      }
    }

    updateLocalState({ selectedItem: localItem });
  };

  const handleChangePrepQuantity = (groupIndex, itemIndex, qty) => {
    let localItem = { ...getLocalState('selectedItem') };

    if (localItem.is_variant) {
      var variantSelected = localItem.variant_item.filter(
        (item) => item.checked,
      );

      variantSelected[0].prep_group[groupIndex].prep_item[itemIndex].quantity =
        qty;
    } else {
      localItem.prep_group[groupIndex].prep_item[itemIndex].quantity = qty;
    }
    updateLocalState({ selectedItem: localItem });
  };

  const disabledButton = () => {
    let localItem = getLocalState('selectedItem');
    let notFullFilled = false;

    //cek jika item yang dipilih merupakan produk variant
    if (localItem.is_variant) {
      //Filter variant item berdasarkan variant item yang di centang
      var variantSelected = localItem.variant_item.filter(
        (item) => item.checked,
      );

      if (variantSelected && variantSelected.length > 0) {
        let hasPrepGroup =
          variantSelected[0].prep_group &&
          variantSelected[0].prep_group.length > 0;

        //Cek apakah variant item yang dipilih memiliki prep group atau modifier
        //jika kondisinya terpenuhi maka nutfullfilled set = true
        if (hasPrepGroup) {
          for (let i = 0; i < variantSelected[0].prep_group.length; i++) {
            let hasPrepItem =
              variantSelected[0].prep_group[i].prep_item.length > 0;
            if (hasPrepItem) {
              let countPrepItem = variantSelected[0].prep_group[
                i
              ].prep_item.filter((x) => x.checked);
              let qtySelected = 0;
              for (let j = 0; j < countPrepItem.length; j++) {
                qtySelected += countPrepItem[j].quantity
                  ? countPrepItem[j].quantity
                  : 1;
              }
              if (qtySelected < variantSelected[0].prep_group[i].choose.min) {
                notFullFilled = true;
                break;
              }
            }
          }
        }

        let hasModifierGroup =
          variantSelected[0].modifier_group &&
          variantSelected[0].modifier_group.length > 0;
        //cek jika ada modifier
        if (hasModifierGroup) {
          for (let i = 0; i < variantSelected[0].modifier_group.length; i++) {
            let hasPrepItem =
              variantSelected[0].modifier_group[i].modifiers.length > 0;
            if (hasPrepItem) {
              let countPrepItem = variantSelected[0].modifier_group[
                i
              ].modifiers.filter((x) => x.checked);
              let qtySelected = 0;
              for (let j = 0; j < countPrepItem.length; j++) {
                qtySelected += countPrepItem[j].quantity
                  ? countPrepItem[j].quantity
                  : 1;
              }
              if (
                qtySelected < variantSelected[0].modifier_group[i].choose.min
              ) {
                notFullFilled = true;
                break;
              }
            }
          }
        }
      } else {
        notFullFilled = true;
      }
    } else {
      let hasPrepGroup =
        localItem && localItem.prep_group && localItem.prep_group.length > 0;

      // CEK SETIAP PREPGROUP & PREPITEMNYA KALAU TIDAK TERPENUHI, NOTFULLFILLED JADI TRUE
      if (hasPrepGroup) {
        for (let i = 0; i < localItem.prep_group.length; i++) {
          let hasPrepItem = localItem.prep_group[i].prep_item.length > 0;
          if (hasPrepItem) {
            let countPrepItem = localItem.prep_group[i].prep_item.filter(
              (x) => x.checked,
            );
            let qtySelected = 0;
            for (let j = 0; j < countPrepItem.length; j++) {
              qtySelected += countPrepItem[j].quantity
                ? countPrepItem[j].quantity
                : 1;
            }
            if (qtySelected < localItem.prep_group[i].choose.min) {
              notFullFilled = true;
              break;
            }
          }
        }
      }

      let hasModifierGroup =
        localItem &&
        localItem.modifier_group &&
        localItem.modifier_group.length > 0;
      if (hasModifierGroup) {
        for (let i = 0; i < localItem.modifier_group.length; i++) {
          let hasPrepItem = localItem.modifier_group[i].modifiers.length > 0;
          if (hasPrepItem) {
            let countPrepItem = localItem.modifier_group[i].modifiers.filter(
              (x) => x.checked,
            );
            let qtySelected = 0;
            for (let j = 0; j < countPrepItem.length; j++) {
              qtySelected += countPrepItem[j].quantity
                ? countPrepItem[j].quantity
                : 1;
            }
            if (qtySelected < localItem.modifier_group[i].choose.min) {
              notFullFilled = true;
              break;
            }
          }
        }
      }
    }

    return notFullFilled;
  };

  return (
    <ReDialog
      title={getLocalState('selectedItem').plu_name}
      closeModal={closeModal}
      useCloseButton={true}
      customTitleStyle={{
        backgroundColor: getColorApp()?.backgroundColor,
        color: getColorApp()?.primaryFontColor,
      }}
      contentStyle={{
        backgroundColor: getColorApp()?.backgroundColor,
        color: getColorApp()?.primaryFontColor,
      }}
      content={
        <Box display="flex" flexDirection="column">
          {getSetting() &&
            getSetting().isImageDetail &&
            getLocalState('selectedItem').url && (
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="center">
                  <Typography
                    component="button"
                    variant="caption"
                    className={classes.buttonImage}
                    style={{
                      backgroundColor: getColorApp()?.secondaryThemeColor,
                      borderColor: getColorApp()?.secondaryThemeColor,
                      outlineColor: getColorApp()?.secondaryThemeColor,
                    }}
                    onClick={() => {
                      setShowImage(!isShowImage);
                    }}>
                    <Box color={getColorApp()?.primaryFontColor}>
                      {isShowImage ? `${t('closeImage')}` : `${t('showImage')}`}
                    </Box>
                  </Typography>
                </Box>
                {isShowImage && (
                  <Box display="flex" flexDirection="column">
                    <ReLazyImage
                      url={getImageFullPage(getLocalState('selectedItem').url)}
                    />
                  </Box>
                )}
              </Box>
            )}

          {getLocalState('selectedItem').description && (
            <Grid item>
              <Typography
                variant="body2"
                style={{
                  whiteSpace: 'pre-wrap',
                  color: getColorApp()?.primaryFontColor,
                }}>
                {getLocalState('selectedItem').description}
              </Typography>
            </Grid>
          )}

          {getSetting() &&
            getSetting().isOrderable &&
            getLocalState('selectedItem').use_note && (
              <Grid item style={{ marginTop: theme.spacing(2) }}>
                <Input
                  className={classes.textField}
                  classes={{ input: classes.input }}
                  style={{ color: getColorApp()?.primaryFontColor }}
                  onChange={(event) => {
                    updateLocalState({
                      additional_request: event.currentTarget.value,
                    });
                  }}
                  placeholder={t('additionalRequest')}
                  inputProps={{ maxLength: 36 }}
                  disableUnderline
                />
              </Grid>
            )}

          {getLocalState('selectedItem').sub_product &&
            getLocalState('selectedItem').sub_product.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePackageItem
                  subProduct={getLocalState('selectedItem').sub_product}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').variant_item &&
            getLocalState('selectedItem').variant_item.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <ReVariantAccordion
                  qtyProduct={getLocalState('qty')}
                  variantItem={getLocalState('selectedItem').variant_item}
                  checkItem={(groupIndex, itemIndex) => {
                    handleCheckVariant(groupIndex, itemIndex);
                  }}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            indexVariantSelected !== -1 &&
            getLocalState('selectedItem').variant_item[indexVariantSelected]
              .use_note && (
              <Grid item style={{ marginTop: theme.spacing(4) }}>
                <Input
                  className={classes.textField}
                  classes={{ input: classes.input }}
                  style={{ color: getColorApp()?.primaryFontColor }}
                  onChange={(event) => {
                    updateLocalState({
                      additional_request: event.currentTarget.value,
                    });
                  }}
                  placeholder={t('additionalRequest')}
                  inputProps={{ maxLength: 36 }}
                  disableUnderline
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            indexVariantSelected !== -1 &&
            getLocalState('selectedItem').variant_item[indexVariantSelected]
              .modifier_group &&
            getLocalState('selectedItem').variant_item[indexVariantSelected]
              .modifier_group.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={
                    getLocalState('selectedItem').variant_item[
                      indexVariantSelected
                    ].modifier_group
                  }
                  checkItem={(groupIndex, itemIndex) => {
                    handleCheckModifier(groupIndex, itemIndex);
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                  isModifier={true}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            indexVariantSelected !== -1 &&
            getLocalState('selectedItem').variant_item[indexVariantSelected]
              .prep_group &&
            getLocalState('selectedItem').variant_item[indexVariantSelected]
              .prep_group.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={
                    getLocalState('selectedItem').variant_item[
                      indexVariantSelected
                    ].prep_group
                  }
                  checkItem={(groupIndex, itemIndex) =>
                    handleCheckPrepItem(groupIndex, itemIndex)
                  }
                  changeQuantity={(groupIndex, itemIndex, prepQty) =>
                    handleChangePrepQuantity(groupIndex, itemIndex, prepQty)
                  }
                  isOrderable={getSetting() && getSetting().isOrderable}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').modifier_group &&
            getLocalState('selectedItem').modifier_group.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={getLocalState('selectedItem').modifier_group}
                  checkItem={(groupIndex, itemIndex) => {
                    handleCheckModifier(groupIndex, itemIndex);
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                  isModifier={true}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').prep_group &&
            getLocalState('selectedItem').prep_group.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={getLocalState('selectedItem').prep_group}
                  checkItem={(groupIndex, itemIndex) =>
                    handleCheckPrepItem(groupIndex, itemIndex)
                  }
                  changeQuantity={(groupIndex, itemIndex, prepQty) =>
                    handleChangePrepQuantity(groupIndex, itemIndex, prepQty)
                  }
                  isOrderable={getSetting() && getSetting().isOrderable}
                />
              </Grid>
            )}
          <Grid item>
            <Grid
              container
              direction="row"
              className={classes.priceAndQtyContainer}>
              <Grid ref={noderef} className={classes.marginTopBottom} item>
                {getSetting() && getSetting().isOrderable && (
                  <ReButtonQuantity
                    onChangeValue={(value) => handleChangeQuantity(value)}
                  />
                )}
              </Grid>
              <Grid
                item
                className={`${classes.price} ${classes.marginTopBottom}`}>
                <Box display="flex" flexDirection="row">
                  <Typography
                    variant="subtitle2"
                    className={classes.marginLeftRight}
                    style={{ color: getColorApp()?.primaryFontColor }}>
                    {getSetting() && getSetting().isOrderable
                      ? 'Sub total : '
                      : `${t('price')} : `}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: getColorApp()?.primaryFontColor }}>
                    {convertCurrency(
                      sumTotalPriceWithPrepItem(
                        getLocalState('selectedItem'),
                        getLocalState('qty'),
                        getSellBandId(),
                      ),
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Button Material UI */}
          {getSetting() && getSetting().isOrderable && (
            <Button
              className={classes.buttonAddToCart}
              style={{
                backgroundColor: disabledButton()
                  ? 'lightgray'
                  : getColorApp()?.buttonAddToCartColor,
                color: getColorApp()?.secondaryFontColor,
              }}
              disabled={disabledButton()}
              variant="contained"
              onClick={() => handleAddToCart()}>
              {t('add')} <AddShoppingCart />
            </Button>
          )}
        </Box>
      }
    />
  );
};

export default ReAddToCartModal;
